import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Particles from "react-particles-js"

import BackgroundImage from "gatsby-background-image"

function halfHeader({ data, className, title }) {
  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    data.mobileImage.fluid,
    {
      ...data.desktopImage.fluid,
      media: `(min-width: 750px)`,
    },
  ]

  const BgImage = styled(BackgroundImage)`
    width: 100%;
    overflow: hidden;
    min-height: 70vh;
    position: relative;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.5);
  `

  return (
    <BgImage
      Tag={`div`}
      id={`media-test`}
      className={className}
      fluid={sources}
    >
      <Particles
        className="snowHalf"
        focusable="true"
        aria-hidden="true"
        params={{
          particles: {
            number: {
              value: 75,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
            color: {
              value: "#f5f5f5",
            },
            shape: {
              type: ["image"],
              image: [
                {
                  src: "./musical-note1.png",
                  height: 85,
                  width: 85,
                },
                {
                  src: "./musical-note2.png",
                  height: 85,
                  width: 85,
                },
                {
                  src: "./musical-note3.png",
                  height: 85,
                  width: 85,
                },
              ],
              stroke: {
                width: 0,
                color: "#ccc",
              },
              polygon: {
                nb_sides: 10,
              },
            },
            opacity: {
              value: 0.9,
              random: true,
              anim: {
                enable: true,
                speed: 0.2,
                opacity_min: 0.5,
                sync: true,
              },
            },
            size: {
              value: 10,
              random: true,
              anim: {
                enable: true,
                speed: 0.2,
                size_min: 6,
                sync: true,
              },
            },
            line_linked: {
              enable: false,
              distance: 200,
              color: "#000",
              opacity: 1,
              width: 2,
            },
            move: {
              enable: true,
              speed: 0.2,
              direction: "top",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: true,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "bounce",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 600,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 500,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />
      <div className="overlay">
        <header className="page-head-half-page">
          <h2 className="page-head-title-half-page">{title}</h2>
        </header>
      </div>
    </BgImage>
  )
}

export default halfHeader

halfHeader.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
}
