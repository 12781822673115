import React from "react";
import { graphql } from "gatsby";
import HalfHeader from "../components/halfBGImageMD";
import Layout from "../components/layout";
import ReactPlayer from "react-player";
import SEO from "../components/seo";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <HalfHeader
          data={post.frontmatter.background}
          title={post.frontmatter.title}
        />

        <article className="post-content page-template no-image">
          <div className="post-content-body">
            <div className="galleryContainer">
              {post.frontmatter.videos.map(( node ) => {
                return (
                  <>
                    <h2>{node.title}</h2>
                    {node.url ?
                      <ReactPlayer
                        className="imageVideoFigure"
                        url={node.url}
                        light={true}
                        config={{
                          youtube: {
                            playerVars: {
                              modestbranding: 1,
                              responsive: 1,
                              fs: 0
                            }
                          }
                        }}
                      />
                      :
                      <></>
                    }
                    <hr/>

                  </>
                );
              })}
            </div>
          </div>
        </article>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query VideosBy($locale: String!, $slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        mdx(
            fields: { locale: { eq: $locale } }
            frontmatter: { slug: { eq: $slug } }
        ) {
            id
            frontmatter {
                title
                description
                videos {
                    url
                    title
            
                }
                background {
                    mobileImage: childImageSharp {
                        fluid(maxHeight: 490, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                    desktopImage: childImageSharp {
                        fluid(maxHeight: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;
